export const productMetafieldsToFetch = [
  { namespace: 'products', key: 'brandname' },
  { namespace: 'products', key: 'productshelflife' },
  { namespace: 'products', key: 'countryoforigin' },
  { namespace: 'products', key: 'containertype' },
  { namespace: 'products', key: 'packof' },
  { namespace: 'products', key: 'formproducts' },
  { namespace: 'products', key: 'producttype' },
  { namespace: 'products', key: 'storageinstructions' },
  { namespace: 'products', key: 'manufacturer' },
  { namespace: 'products', key: 'addedpreservatives' },
  { namespace: 'products', key: 'ingredients' },
  { namespace: 'products', key: 'addedsugar' },
  { namespace: 'products', key: 'dietarypreference' },
  { namespace: 'products', key: 'suitablefor' },
  { namespace: 'products', key: 'foodpreferences' },
  { namespace: 'products', key: 'usedfor' },
  { namespace: 'products', key: 'directionsofuse' },
  { namespace: 'products', key: 'nutritionalbenefits' },
  { namespace: 'products', key: 'nutritioncontent' },
  { namespace: 'products', key: 'avg_rating' },
  { namespace: 'products', key: 'review_count' },
  { namespace: 'products', key: 'nutritional_data' },
  { namespace: 'my_fields', key: 'testimonial_author_name' },
  { namespace: 'my_fields', key: 'testimonial_author_post' },
  { namespace: 'my_fields', key: 'testimonial_quote' },
  { namespace: 'my_fields', key: 'usp_heading-1' },
];
