import formatTitle from 'title';
import { Merge } from 'type-fest';
import truncate from 'lodash/truncate';
import base64 from 'base-64';
import {
  ShopifyService,
  GetProductListQuery,
  GetProductListQueryVariables,
  GetProductByCollectionQueryVariables,
  GetProductByCollectionQuery,
  GetallCollectionsQueryVariables,
  CurrencyCode,
  GetProductMetafieldsQueryVariables,
  GetProductMetafieldsQuery,
} from './shopify.service';
import { product_suitable_for_images } from '@app/utilities/usp_to_links.utility';
import { sanitize } from '@app/utilities/common.utility';
import { productMetafieldsToFetch } from '@app/utilities/shopify.metafields';

export namespace ProductService {
  export type MetafieldType = { __typename?: 'Metafield' | undefined; value: string } | null | undefined;
  export interface Single {
    id: string;
    title: string;
    description: string;
    seo: {
      title: string;
      description: string;
    };
    images: {
      id: string;
      src: string;
      alt: string;
    }[];
    handle?: string;
    variants: {
      sku: string;
      id: string;
      title: string;
      image?: string;
      weight?: number | null;
      price: {
        amount: number;
        currencyCode: CurrencyCode;
        compareAmt: number;
      };
      selectedOptions: Array<{ name: string; value: string }>;
      availableForSale: boolean;
    }[];
    brandName: MetafieldType;
    packOf: MetafieldType;
    productLife: MetafieldType;
    foodPreference: MetafieldType;
    addedPreservatives: MetafieldType;
    dietaryPreference: MetafieldType;
    directionsOfUse: MetafieldType;
    storageInstructions: MetafieldType;
    suitablefor1?: MetafieldType;
    suitableFor?: MetafieldType;
    vendor?: string;
    addedSugar: MetafieldType;
    type: MetafieldType;
    ingredients: MetafieldType;
    countryOfOrigin: MetafieldType;
    manufacturer: MetafieldType;
    containerType: MetafieldType;
    form: MetafieldType;
    usedFor: MetafieldType;
    nutritionalBenefits: MetafieldType;
    nutritionContent: MetafieldType;
    nutritionRecommendation?: Array<string>;
    box_description?: MetafieldType;
  }

  export async function getSingle(handle: string): Promise<Single | null | undefined> {
    const productData = await ShopifyService.getProductSingle({ handle });
    const product = productData?.product;

    if (!product) {
      return product;
    }

    const {
      id,
      title,
      description,
      seo,
      images,
      variants,
      brandName,
      packOf,
      productLife,
      foodPreference,
      addedPreservatives,
      dietaryPreference,
      storageInstructions,
      suitablefor1,
      suitablefor2,
      addedSugar,
      type,
      ingredients,
      countryOfOrigin,
      manufacturer,
      containerType,
      form,
      usedFor,
      directionsOfUse,
      nutritionalBenefits,
      nutritionContent,
      vendor,
      nutritionRecommendation,
      availableForSale,
      avgRating,
      box_subtitle,
      box_description,
    } = product!;

    let filteredRecommendation: string[] = [];
    if (nutritionRecommendation) {
      nutritionRecommendation.value.split('<li>').map((i) => {
        if (i) {
          filteredRecommendation.push(i?.replaceAll ? i?.replaceAll?.('</li>', '') : i);
        }
      });
    }
    const productByHandle: Single = {
      id,
      title: formatTitle(title),
      description,
      vendor,
      seo: {
        title: formatTitle(seo.title || title),
        description: seo.description || truncate(description, { length: 256 }),
      },
      images: images.edges.map(({ node }) => {
        return {
          id: node.id as string,
          src: node.url,
          alt: node.altText || '',
        };
      }),
      variants: variants.edges.map(({ node }) => {
        const variant: Single['variants'][0] = {
          sku: node.sku!,
          id: node.id,
          title: node.title,
          image: node.image?.id!,
          price: {
            amount: Number(node.price.amount),
            currencyCode: node.price.currencyCode,
            compareAmt: node?.compareAtPrice?.amount ? Number(node.compareAtPrice.amount) : 0,
          },
          availableForSale: node?.availableForSale,
          selectedOptions: node.selectedOptions.map((option) => ({ name: option.name, value: option.value })),
        };
        return variant;
      }),
      brandName,
      packOf,
      productLife,
      foodPreference,
      addedPreservatives,
      dietaryPreference,
      directionsOfUse,
      storageInstructions,
      suitablefor1,
      addedSugar,
      type,
      ingredients,
      countryOfOrigin,
      manufacturer,
      containerType,
      form,
      usedFor,
      nutritionalBenefits,
      nutritionContent,
      nutritionRecommendation: filteredRecommendation,
      avgRating,
      box_subtitle,
      box_description,
    };
    productByHandle.id = base64.encode(id).toString();

    return productByHandle;
  }

  export async function getProductById(ID: string): Promise<Single | null | undefined> {
    const { product } = await ShopifyService.getProductById({ id: ID });

    if (!product) {
      return product;
    }

    const {
      id,
      title,
      description,
      seo,
      images,
      variants,
      brandName,
      packOf,
      productLife,
      foodPreference,
      addedPreservatives,
      dietaryPreference,
      storageInstructions,
      suitableFor,
      addedSugar,
      type,
      ingredients,
      countryOfOrigin,
      manufacturer,
      containerType,
      form,
      usedFor,
      directionsOfUse,
      nutritionalBenefits,
      nutritionContent,
      handle,
    } = product!;

    const productByHandle: Single = {
      id,
      title: formatTitle(title),
      description,
      seo: {
        title: formatTitle(seo.title || title),
        description: seo.description || truncate(description, { length: 256 }),
      },
      images: images.edges.map(({ node }) => {
        return {
          id: node.id as string,
          src: node.url,
          alt: node.altText || '',
        };
      }),
      handle,
      variants: variants.edges.map(({ node }) => {
        const variant: Single['variants'][0] = {
          id: node.id,
          title: node.title,
          image: node.image?.id!,
          weight: node?.weight,
          price: {
            amount: Number(node.price.amount),
            currencyCode: node.price.currencyCode,
            compareAmt: node?.compareAtPrice?.amount ? Number(node.compareAtPrice.amount) : 0,
          },
          selectedOptions: node.selectedOptions.map((option) => ({ name: option.name, value: option.value })),
          availableForSale: node.availableForSale,
        };
        return variant;
      }),

      brandName,
      packOf,
      productLife,
      foodPreference,
      addedPreservatives,
      dietaryPreference,
      directionsOfUse,
      storageInstructions,
      suitableFor,
      addedSugar,
      type,
      ingredients,
      countryOfOrigin,
      manufacturer,
      containerType,
      form,
      usedFor,
      nutritionalBenefits,
      nutritionContent,
    };
    productByHandle.id = base64.encode(id).toString();
    return productByHandle;
  }

  export interface ListItem {
    id?: string;
    url?: string;
    title?: string;
    description?: string;
    variants?: {
      id: string;
    };
    image?: {
      src: string;
      alt: string;
    };
    price?: {
      amount: number;
      currencyCode: CurrencyCode;
    };
  }

  export interface ListItem2 {
    availableForSale: boolean;
    id: string;
    cursor: string;
    url: string;
    title: string;
    description: string;
    handle: string;
    productType: string;
    tags: string[];
    vendor: string;
    image: {
      src: string;
      alt: string;
    };
    varient: {
      id: string;
      weight: number | null | undefined;
      weightUnit: string;
      selectedOptions: Array<{ name: string; value: string }>;
    };
    price: {
      amt: string;
      compareAmt?: string;
    };
    curated_by?: { author?: string; post?: string; quote?: string };
    brandName: string;
    packOf: string;
    productLife: string;
    foodPreference: string;
    addedPreservatives: string;
    dietaryPreference: string;
    directionsOfUse: string;
    storageInstructions: string;
    suitablefor1: string;
    addedSugar: string;
    type: string;
    ingredients: string;
    countryOfOrigin: string;
    manufacturer: string;
    containerType: string;
    form: string;
    usedFor: string;
    nutritionalBenefits: string;
    nutritionContent: string;
    nutritionRecommendation?: Array<string>;
    nutritional_data?: {
      energy?: Value;
      carbs?: Value;
      fat?: Value;
      saturated_fat?: Value;
      added_sugar?: Value;
      sodium?: Value;
      cholesterol?: Value;
      protein?: Value;
      trans_fat?: Value;
      sugar?: Value;
      dietary_fiber?: Value;
      title?: string;
      serving_size?: string;
    };
  }

  type Value = {
    value?: string;
  };

  export interface List2 {
    // products: Merge<ListItem2, { cursor: string }>[];
    products: ListItem2[];
    pageInfo: GetProductListQuery['products']['pageInfo'];
    description?: string;
    title?: string;
    collection_image?: string;
  }

  export interface List {
    products: Merge<ListItem, { cursor: string }>[];
    pageInfo: GetProductListQuery['products']['pageInfo'];
    description?: string;
    title?: string;
    collection_image?: string;
  }

  export interface ListItem3 {
    // availableForSale: boolean;
    id: string;
    cursor: string;
    url: string;
    title: string;
    description: string;
    handle: string;
    productType: string;
    tags: string[];
    vendor: string;
    image: {
      src: string;
      alt: string;
    };
    varient: {
      id: string;
      // weight: number | null | undefined;
      // weightUnit: string;
    };
    price: {
      amt: string;
      amount: number;
      compareAmt?: string;
    };
  }

  export interface CollectionProductList {
    products: Merge<ListItem3, { cursor: string }>[];
    pageInfo:
      | {
          __typename?: 'PageInfo' | undefined;
          hasNextPage: boolean;
        }
      | undefined;
    description?: string;
    title?: string;
    collection_image?: string;
  }

  export async function getList(variables?: GetProductListQueryVariables): Promise<List> {
    const {
      products: { edges, pageInfo },
    } = await ShopifyService.getProductList(variables);

    const products: List['products'] = edges.map(({ node, cursor }) => {
      return {
        id: base64.encode(node.id).toString(),
        cursor: cursor,
        url: `/products/${node.handle}`,
        title: formatTitle(node.title),
        description: node.description,
        image: {
          src: node.images.edges[0].node.url,
          alt: node.images.edges[0].node.altText || '',
        },
        price: {
          amount: Number(node.priceRange.minVariantPrice.amount),
          currencyCode: node.priceRange.minVariantPrice.currencyCode,
          // amt: node?.priceRange. .variants?.edges[0]?.node?.priceV2?.amount,
        },
      };
    });

    return { products, pageInfo };
  }

  export async function getCollectionProducts(
    variables: GetProductByCollectionQueryVariables
  ): Promise<CollectionProductList> {
    const data = await ShopifyService.getProductByCollection(variables);
    const description = data?.collection?.description;
    const title = data?.collection?.title;
    const collection_image = data?.collection?.image?.url;

    const products = data?.collection?.products?.edges.map(({ node, cursor }) => {
      return {
        id: base64.encode(node.id).toString(),
        cursor: cursor,
        url: `/products/${node?.handle}`,
        title: formatTitle(node?.title),
        description: node.title,
        handle: node.handle,
        productType: node.productType,
        vendor: node.vendor,
        image: {
          src: node?.images?.edges[0]?.node?.url,
          alt: node?.images?.edges[0]?.node?.url || '',
        },
        varient: {
          id: node?.variants?.edges[0]?.node?.id,
        },
        price: {
          amt: node?.variants?.edges[0]?.node?.price?.amount,
          amount: Number(node?.variants?.edges[0]?.node?.price?.amount),
          compareAmt: node?.variants?.edges[0]?.node?.compareAtPrice?.amount,
          // currencyCode: node.priceRange.minVariantPrice.currencyCode,
        },
      };
    }) as ListItem3[];
    const pageInfo = data?.collection?.products?.pageInfo;
    return { products, pageInfo, description, title, collection_image };
  }

  export function tWeightUnit(unit: string) {
    switch (unit) {
      case 'KILOGRAMS':
        return 'Kg';
      case 'GRAMS':
        return 'Gm';
      default:
        return unit;
    }
  }

  export async function getCollection(handle: string, number_of_items?: number, cursor?: string): Promise<List2> {
    const data = await ShopifyService.getProductByCollection({ handle, number_of_items, cursor });

    if (!data?.collection) {
      return data;
    }

    const products = data?.collection?.products?.edges.map(({ node, cursor }) => {
      let nutritional_data_json = sanitize(node?.nutritional_data?.value);
      try {
        nutritional_data_json = JSON.parse(nutritional_data_json);
      } catch (error) {
        nutritional_data_json = '';
      }
      return {
        availableForSale: node?.availableForSale,
        id: base64.encode(node.id).toString(),
        cursor: cursor,
        url: `/products/${node?.handle}`,
        title: formatTitle(node?.title),
        description: sanitize(node.title),
        handle: node.handle,
        productType: node?.productType,
        vendor: node?.vendor,
        tags: node?.tags,
        image: {
          src: node?.images?.edges[0]?.node?.url,
          alt: node?.images?.edges[0]?.node?.url || '',
        },
        varient: {
          id: node?.variants?.edges[0]?.node?.id,
          weight: node?.variants?.edges[0]?.node?.weight,
          weightUnit: tWeightUnit(node?.variants?.edges[0]?.node?.weightUnit),
          selectedOptions: node.variants.edges[0].node.selectedOptions.map(({ __typename, ...rest }) => ({ ...rest })),
        },
        price: {
          amt: node?.variants?.edges[0]?.node?.price?.amount,
          compareAmt: node?.variants?.edges[0]?.node?.compareAtPrice?.amount,
        },
        curated_by:
          !node?.author?.value && !node?.post?.value && !node?.quote?.value
            ? null
            : { author: node?.author?.value, post: node?.post?.value, quote: node?.quote?.value },
        brandName: sanitize(node?.brandName?.value),
        packOf: sanitize(node?.packOf?.value),
        productLife: sanitize(node?.productLife?.value),
        foodPreference: sanitize(node?.foodPreference?.value),
        addedPreservatives: sanitize(node?.addedPreservatives?.value),
        dietaryPreference: sanitize(node?.dietaryPreference?.value),
        directionsOfUse: sanitize(node?.directionsOfUse?.value),
        storageInstructions: sanitize(node?.storageInstructions?.value),
        suitableFor: sanitize(node?.suitableFor?.value),
        addedSugar: sanitize(node?.addedSugar?.value),
        type: sanitize(node?.type?.value),
        ingredients: sanitize(node?.ingredients?.value),
        countryOfOrigin: sanitize(node?.countryOfOrigin?.value),
        manufacturer: sanitize(node?.manufacturer?.value),
        containerType: sanitize(node?.containerType?.value),
        form: sanitize(node?.form?.value),
        usedFor: sanitize(node?.usedFor?.value),
        nutritionalBenefits: sanitize(node?.nutritionalBenefits?.value),
        nutritionContent: sanitize(node?.nutritionContent?.value),
        avgRating: sanitize(node?.avgRating?.value),
        ratingCount: sanitize(node?.ratingCount?.value),
        usp_heading1: sanitize(node?.usp_heading1?.value),
        nutritional_data: nutritional_data_json,
      };
    });
    const pageInfo = data?.collection?.products?.pageInfo;
    return { products, pageInfo };
  }

  export async function getCollectionList(variables?: GetallCollectionsQueryVariables) {
    const data = await ShopifyService.getallCollections();
    const products = data?.collections?.edges?.map(({ node, cursor }) => {
      return {
        id: base64.encode(node.id).toString(),
        cursor: cursor,
        // url: `/products/${node.}`,
        title: formatTitle(node.title),
        description: node.title,
        handle: node.handle,
        image: {
          src: node.image?.url,
          alt: node.image?.url || '',
        },
        // price: {
        // amount: node.,
        // currencyCode: node.priceRange.minVariantPrice.currencyCode,
        // },
      };
    });
    const pageInfo = data?.collections?.pageInfo;
    return { products, pageInfo };
  }

  export async function getProductMetafields(ID: string) {
    const data = await ShopifyService.getProductMetafields({ id: ID });
    const productData = data.product;
    let suitableForIconList = [];
    const iconFields = ['usp_1', 'usp_2', 'usp_3', 'usp_4', 'usp_5', 'usp_6'];
    const iconHeading = [
      'usp_heading-1',
      'usp_heading-2',
      'usp_heading-3',
      'usp_heading-4',
      'usp_heading-5',
      'usp_heading-6',
    ];
    for (let i = 0; i < 7; i++) {
      if (productData && productData[iconFields[i]]?.value && productData[iconHeading[i].replace('-', '_')]?.value) {
        if (product_suitable_for_images[productData[iconFields[i]].value]) {
          suitableForIconList.push({
            icon: product_suitable_for_images[productData[iconFields[i]].value],
            title: productData[iconHeading[i].replace('-', '_')].value,
          });
        }
      }
    }

    return suitableForIconList;
  }

  export interface ProductByTag {
    isCuratedBox: boolean;
    tags: string[];
    id: string;
    handle: string;
    image: string;
    title: string;
    cursor: string;
    variants: {
      id: string;
      price: {
        amount: number;
        compareAmt: number;
      };
      availableForSale: boolean;
    }[];
    metafields: {
      id: string;
      key: string;
      value: string;
    }[];
  }

  export interface ProductByTagList {
    products: ProductByTag[] | [];
    pageInfo: {
      __typename?: 'PageInfo' | undefined;
      hasNextPage: boolean;
    };
  }

  export async function getProductByTag(query: string, cursor?: string): Promise<ProductByTagList> {
    const products = await ShopifyService.getProductsByTag({
      query,
      cursor,
      metafieldIdentifiers: productMetafieldsToFetch,
    });

    const pageInfo = products?.products?.pageInfo;

    const productsArr = products.products.edges.map(({ node, cursor }) => {
      return {
        cursor: cursor,
        isCuratedBox: true,
        tags: node?.tags,
        id: base64.encode(node.id).toString(),
        handle: sanitize(node?.handle),
        image: sanitize(node?.images?.edges?.[0]?.node?.url),
        title: sanitize(node?.title),
        variants: node?.variants.edges.map(({ node }) => {
          const variant = {
            id: node.id,
            price: {
              amount: Number(node.price.amount),
              compareAmt: node?.compareAtPrice?.amount ? Number(node.compareAtPrice.amount) : 0,
            },
            availableForSale: node?.availableForSale,
          };
          return variant;
        }),
        metafields: node?.metafields?.map((node) => {
          const metafield = {
            id: node?.id,
            key: node?.key,
            value: node?.value,
          };
          return metafield;
        }),
      };
    });

    return { products: productsArr, pageInfo };
  }

  export async function getSimilarProducts(productId: string): Promise<List> {
    const data = await ShopifyService.getSimilarProducts({ productId });

    if (!data?.productRecommendations) {
      return data;
    }

    const products = data?.productRecommendations.map((node) => {
      return {
        id: base64.encode(node.id).toString(),
        url: `/products/${node?.handle}`,
        title: formatTitle(node?.title),
        description: sanitize(node.title),
        handle: node.handle,
        productType: node?.productType,
        vendor: node?.vendor,
        tags: node?.tags,
        image: {
          src: node?.images?.edges[0]?.node?.url,
          alt: node?.images?.edges[0]?.node?.url || '',
        },
        variant: {
          id: node?.variants?.edges[0]?.node?.id,
          selectedOptions: node.variants.edges[0].node.selectedOptions.map(({ __typename, ...rest }) => ({ ...rest })),
        },
        price: {
          amt: node?.variants?.edges[0]?.node?.price?.amount,
          compareAmt: node?.variants?.edges[0]?.node?.compareAtPrice?.amount,
        },
      };
    });
    return { products };
  }

  export async function getProductsByIds(productIds: string[]) {
    const data = await ShopifyService.getProductsByIds({ productIds });
    const products = data?.nodes?.map((p) => {
      // if (p?.__typename === 'Product') {
      const item = {
        id: base64.encode(p?.id).toString(),
        title: p?.title,
        url: `/products/${p?.handle}`,
        image: p?.images?.edges?.[0]?.node?.url,
        price: {
          amount: p?.variants?.edges?.[0]?.node?.price,
          compareAtPrice: p?.variants?.edges?.[0]?.node?.compareAtPrice,
        },
        variant: { id: p?.variants?.edges?.[0]?.node?.id },
      };
      return item;
      // }
    });
    return { products };
  }
}
export async function getProductImageById(productId: string) {
  var reg = /^\d+$/;
  if (reg.test(productId)) {
    productId = 'gid://shopify/Product/' + productId;
  }
  const responseImage = await ShopifyService.getProductImageById({ productId });
  return responseImage.product?.images?.edges?.[0]?.node?.url;
}

export async function getAllCollectionOfProduct(productId: string) {
  const collections = await ShopifyService.getCollectionsOfProduct({ productId });
  return collections.product?.collections.edges.map((item) => item.node.handle) || [];
}
