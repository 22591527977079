export const product_suitable_for_images: any = {
  'Usp-alcohol-free': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/alcohol-free.png?v=1640001247',
  'Usp-dairy-free': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/dairy-free.png?v=1640072798',
  'Usp-no-added-sugar': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/no-added-sugar.png?v=1640001960',
  'Usp-low-sodium': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/low-sodium.png?v=1640266968',
  'Usp-nuts-free': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/nuts-free.png?v=1640267108',
  'Usp-preservative-free': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/preservative-free.png?v=1640414936',
  'Usp-jain': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/jain.png?v=1640002322',
  'Usp-non-gmo': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/non-gmo.png?v=1640001960',
  'Usp-peanut-free': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/peanut-free.png?v=1640001960',
  'Usp-soy-free': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/soy-free.png?v=1640001960',
  'Usp-egg-free': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/egg-free.png?v=1641883895',
  'Usp-no-sulphites': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/no-sulphites.png?v=1640267524',
  'Usp-fibre-rich': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/fibre-rich.png?v=1641280257',
  'Usp-high-protein': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/high-protein.png?v=1640001855',
  'Usp-low-fat': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/low-fat.png?v=1642063620',
  'Usp-energy': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/energy.png?v=1640267703',
  'Usp-probiotic': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/probiotic.png?v=1640002706',
  'Usp-caffeine-free': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/caffeine-free.png?v=1641888067',
  'Usp-cholesterol-free': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/cholesterol-free.png?v=1640001201',
  'Usp-no-trans-fats': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/no-trans-fats.png?v=1640415440',
  'Usp-no-additives': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/no-additives.png?v=1640267761',
  'Usp-contains-egg': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/contains-egg.png?v=1642063620',
  'Usp-gut-friendly': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/gut-friendly.png?v=1640001855',
  'Usp-electrolytes': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/electrolytes.png?v=1640267797',
  'Usp-nutrient-dense': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/nutrient-dense.png?v=1640001960',
  'Usp-low-calorie': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/low-calorie.png?v=1640064073',
  'Usp-prebiotic': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/prebiotic.png?v=1642063620',
  'Usp-vegan': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/vegan.png?v=1640002866',
  'Usp-halal': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/halal.png?v=1640001855',
  'Usp-organic': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/organic.png?v=1640073363',
  'Usp-sustainable': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/sustainable.png?v=1640072728',
  'Usp-ayurvedic': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/ayurvedic.png?v=1640001247',
  'Usp-gluten-free': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/gluten-free.png?v=1640001855',
  'Usp-keto': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/keto.png?v=1640002496',
  'Usp-low-processing': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/low-processing.png?v=1640414397',
  'Usp-heart-health': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/heart-health.png?v=1640344406',
  'Usp-bone-joints': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/bone-joints.png?v=1640343973',
  'Usp-brain-health': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/brain-health.png?v=1640001201',
  'Usp-detoxification': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/detoxification.png?v=1640001201',
  'Usp-digestion': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/digestion.png?v=1640416310',
  'Usp-skin-health': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/skin-health.png?v=1640001960',
  'Usp-builds-immunity': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/builds-immunity.png?v=1640067666',
  'Usp-liver-health': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/liver-health.png?v=1640001960',
  'Usp-nerve-health': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/nerve-health.png?v=1640069781',
  'Usp-stress-anxiety': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/stress-anxiety.png?v=1640001959',
  'Usp-metabolism': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/metabolism.png?v=1640002619',
  'Usp-iron-rich': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/iron-rich.png?v=1640268962',
  'Usp-fda-certified': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/fda-certified.png?v=1640069096',
  'Usp-female-fertility': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/female-fertility.png?v=1640063266',
  'Usp-post-pregnancy': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/post-pregnancy.png?v=1640001960',
  'Usp-sexual-wellness': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/sexual-wellness.png?v=1640001959',
  'Usp-menstrual-health': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/menstrual-health.png?v=1640066120',
  'Usp-acne-control': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/acne-control.png?v=1640070598',
  'Usp-thyroid-care': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/thyroid-care.png?v=1640001960',
  'Usp-anti-ageing': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/anti-ageing.png?v=1640001247',
  'Usp-gut-health': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/gut-health.png?v=1640001855',
  'Usp-piles': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/piles.png?v=1640001960',
  'Usp-asthama': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/asthama.png?v=1640001247',
  'Usp-constipation': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/constipation.png?v=1641885176',
  'Usp-hypertension': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/hypertension.png?v=1640001855',
  'Usp-kidney': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/kidney.png?v=1640267833',
  'Usp-fatigue': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/fatigue.png?v=1641629791',
  'Usp-male-fertility-testosterone':
    'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/male-fertility-testosterone.png?v=1640604088',
  'Usp-altitude-sickness': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/altitude-sickness.png?v=1642064255',
  'Usp-anti-hairfall': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/anti-hairfall.png?v=1640267971',
  'Usp-hair-growth': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/hair-growth.png?v=1640001855',
  'Usp-fat-reduction': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/fat-reduction.png?v=1640065497',
  'Usp-blood-sugar': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/blood-sugar.png?v=1640001201',
  'Usp-cholesterol': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/cholesterol.png?v=1640929473',
  'Usp-cough': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/cough.png?v=1642064255',
  'Usp-cold-flu': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/cold-flu.png?v=1640001201',
  'Usp-lungs-health': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/lungs-health.png?v=1640072411',
  'Usp-menopause': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/menopause.png?v=1642064327',
  'Usp-urinary-tract-infection':
    'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/urinary-tract-infection.png?v=1640001959',
  'Usp-hormonal-balance': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/hormonal-balance.png?v=1642064435',
  'Usp-sleep': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/sleep.png?v=1640268210',
  'Usp-endurance': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/endurance.png?v=1642064570',
  'Usp-bulk-up': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/bulk-up.png?v=1640268245',
  'Usp-stamina': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/stamina.png?v=1641899571',
  'Usp-lean-muscle': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/lean-muscle.png?v=1640268901',
  'Usp-muscle-building': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/muscle-building.png?v=1641880560',
  'Usp-muscle-recovery': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/muscle-recovery.png?v=1640268817',
  'Usp-weight-gain': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/weight-gain.png?v=1640268734',
  'Usp-workout-performance':
    'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/workout-performance.png?v=1641635251',
  'Usp-meal-replacement': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/meal-replacement.png?v=1640001960',
  'Usp-weight-loss': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/weight-loss.png?v=1641887463',
  'Usp-added-herbs': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/added-herbs.png?v=1640268835',
  'Usp-maintain-weight': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/maintain-weight.png?v=1640268817',
  'Usp-suppress-hunger': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/suppress-hunger.png?v=1641280122',
  'Usp-body-building': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/body-building.png?v=1640001201',
  'Usp-womens-health': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/womens-health.png?v=1640001960',
  'Usp-obesity': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/obesity.png?v=1640587766',
  'Usp-eye-health': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/eye-health.png?v=1640001080',
  'Usp-plant': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/plant.png?v=1640001960',
  'Usp-vitamins-minerals-fortified':
    'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/vitamins-minerals-fortified.png?v=1640001959',
  'Usp-women': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/women.png?v=1640002822',
  'Usp-kids': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/kids.png?v=1640001960',
  'Usp-botanicals': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/botanicals.png?v=1640268875',
  'Usp-older-adults': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/older-adults.png?v=1640001960',
  'Usp-nabl-certified': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/nabl-certified.png?v=1640268848',
  'Usp-labdoor-certified': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/labdoor_certified.png?v=1641448758',
  'Usp-gmp-certified': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/gmp-certified.png?v=1641629147',
  'Usp-nail-care': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/nail-care.png?v=1640065497',
  'Usp-builds-strength': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/builds-strength.png?v=1641637931',
  'Usp-one-ingredient-diet':
    'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/one-ingredient-diet.png?v=1640001960',
  'Usp-cold-pressed': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/cold-pressed.png?v=1640001201',
  'Usp-lactose-free': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/lactose-free.png?v=1640072855',
  'Usp-non-vegetarian': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/non-vegetarian.png?v=1640062296',
  'Usp-men': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/men.png?v=1640063821',
  'Usp-after-meals': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/after-meals.png?v=1640001247',
  'Usp-animal-protein': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/animal-protein.png?v=1640001247',
  'Usp-arthritis': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/arthritis.png?v=1641207364',
  'Usp-before-bed': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/before-bed.png?v=1640001247',
  'Usp-before-meal': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/before-meal.png?v=1640001247',
  'Usp-blood-pressure': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/blood-pressure.png?v=1640002960',
  'Usp-brain-memory': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/brain-and-memory.png?v=1640001201',
  'Usp-brain-development': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/brain-development.png?v=1640001201',
  'Usp-dietary-supplement': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/dietary-suplement.png?v=1640572597',
  'Usp-erectile-dysfunction':
    'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/erectile-dysfunction.png?v=1640001080',
  'Usp-lactation': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/lactation.png?v=1640001960',
  'Usp-mock-meat': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/mock-meat.png?v=1640001960',
  'Usp-physical-growth': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/physical_growth.png?v=1640001960',
  'Usp-pregnancy': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/pregnancy.png?v=1640002651',
  'Usp-prostate': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/prostate.png?v=1640002724',
  'Usp-45-protein': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/45-protein.png?v=1642238826',
  'Usp-65-protein': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/65-protein.png?v=1642238829',
  'Usp-75-protein': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/75-protein.png?v=1642238826',
  'Usp-85-protein ': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/85-protein.png?v=1642238826',
  'Usp-44-protein': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/44-protein.png?v=1642238810',
  'Usp-dairy': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/dairy.png?v=1642240719',
  'Usp-plant-based': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/plant-based.png?v=1642239960',
};
